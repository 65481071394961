//获取我处理的工单
const getMyServiceWorkList = `/gateway/blade-system/workInfoApp/getMyServiceWorkList`;
//根据ID获取详情
const getWorkInfoById = `/gateway/blade-system/workInfoApp/getWorkInfoById`;
//保存或更新
const saveOrUpdateWorkInfo = `/gateway/blade-system/workInfo/saveOrUpdateWorkInfo`;
//物联网设备列表
const getDeviceList = `/gateway/hc-device/iot/device/list`;
// 上传签名
const uploadURL = `/gateway/blade-resource/oss/endpoint/put-file`;

export {
  getMyServiceWorkList,
  getWorkInfoById,
  saveOrUpdateWorkInfo,
  getDeviceList,
  uploadURL,
};
